import GoToTop from "../helpers/GoToTop";
import MetaData from "../helpers/MetaData";
import { Link } from "react-router-dom";

const RefundPolicy = () => {
    return ( 
        <>
         <MetaData title="Refund Policy" />
         <section className="refund-policy-sec">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 mx-auto">
                            <div className="heading">
                                <h2 className="head-1">REFUND POLICY</h2>
                                
                                <p>Our policy on refund and cancellation of donations received for ISKCON on secure online payment gateway as under:</p>
                                <ol>
                                    <li>Any request for cancellations and refund of online donations once duly placed on the website, shall not be entertained under any circumstances.</li>
                                    <li>No cash or refund of money will be allowed after completing the online donation as it is an extremely cumbersome process. We therefore request you to be sure before you donate.</li>
                                    <li>No refund/cancellation request for the donated amount by any donor will be entertained for online donations through the online payment gateway after the donation is accepted from our Online Payment Gateway service providers unless there is a fraud notification from our Payment Gateway Service Providers.</li>
                                </ol>
                              
                                <div className="terms-and-condition-btn mt-5">
                                    <Link className="custom-btn-cls box-hover" to="/privacy-policy">Privacy Policy</Link>
                                    <Link className="custom-btn-cls box-hover" to="/refund-policy">Refund policy</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                   
                </div>
            </section>
         <GoToTop />
        </>
     );
}
 
export default RefundPolicy;