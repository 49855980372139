import React, { useCallback, useEffect, useState } from 'react'
import fileDownload from 'js-file-download'
import notification from '../helpers/notification'
import ApiService from '../api/ApiService'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import GoToTop from '../helpers/GoToTop'
import Skeleton from 'react-loading-skeleton'
import { BsFileText, BsChevronLeft } from 'react-icons/bs'
import { IoCheckmark } from 'react-icons/io5'
import { TbExclamationMark } from 'react-icons/tb'
import MetaData from '../helpers/MetaData'
import PageHeader from '../components/common/PageHeader'
import { FaFacebookF, FaLinkedinIn, FaTwitter, FaDownload } from 'react-icons/fa'
import OnDemandCourses from "../components/homepage/OnDemandCourses"
import { Link } from 'react-router-dom'
import CampaignSlider from '../components/campaign/CampaignSlider'
import { useContext } from 'react'
import AppContext from '../context/AppContext'
import { Modal } from 'react-bootstrap'

function ThankYou() {
  const { id } = useParams()
  const { type } = useParams()
  const filename = `${id}.pdf`
  const imgname = `${id}.jpg`
  const [showDownload, setShowDownload] = useState(false)
  const [showCertificate, setShowCertificate] = useState(false)
  const [paymentDetails, setPaymentDetails] = useState(null)
  const [campaigns, setCampaigns] = useState([]);
  const [timer, setTimer] = useState(true)
  const [show, setShow] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [eightyGGenerated, setEightyGGenerated] = useState(false)
  const { fetchUser, user, setLoginPopup } = useContext(AppContext)
  const [ics_data, setICSdata] = useState([])
  const [ics_id, setIcs_id] = useState(null)
  const [top, setTop] = useState(0);
  const navigate = useNavigate();

  // if(id){
  //   setTimeout(() => {
  //     const scrollToDonateFromRef = document.getElementById('scrollToThankYouPage');
  //     if (scrollToDonateFromRef) {
  //       const offset = -600;
  //       const targetScrollTop = scrollToDonateFromRef.offsetTop - offset;
  //       const container = document.documentElement;
  //       container.scrollTop = targetScrollTop;
  //     } else {
  //       console.error("Element scrollToDonateFromRef not found!");
  //     }
  //   }, 2000);
  // }

  const sectionRef = useCallback((node) => {
    if (node) {
      let headerHeight;
      if (window.innerWidth > 991) {
        headerHeight = 121;
      } else if (window.innerWidth > 576) {
        headerHeight = 80;
      } else {
        headerHeight = 73;
      }
      setTop(node?.offsetTop - headerHeight);
    }
  });

  //console.log(ics_data);
  const handelForm = async () => {

    const { data } = await ApiService.getIcsDonation_b(paymentDetails.phone)
    if (paymentDetails.type === 'Nitya-Seva') {
      const icsId = data.find(d => d.orderId === paymentDetails.instrument_no)?.icsid;
      setIcs_id(icsId)
    } else {
      const icsId = data.find(d => d.paymentId === paymentDetails.instrument_no)?.icsid
      setIcs_id(icsId)
    }

    // const response = await ApiService.getIcsDonation_b(paymentDetails.phone)
    // console.log(response.data.find(dat => dat.rno == paymentDetails.eightyGNo).icsid);
    // setIcs_id(response.data.find(dat => dat.rno == paymentDetails.eightyGNo).icsid);
  }



  const url = useLocation()
  let donationType = url.pathname.split('/')[url.pathname.split('/').length - 1];

  const fetchPaymentDetails = async () => {
    try {
      const res = await ApiService.fetchPaymentDetails(id, donationType)
      // console.log(res)
      setPaymentDetails(res)
    } catch (err) {
      console.log(err)
      notification('error', 'Something went wrong')
    }
  }

  const handleFileDownload = async () => {
    try {

      if (paymentDetails.isGotoIcs) {
        const res = await ApiService.download80g(filename)
        const receipt = paymentDetails.eightyGNo;
        // console.log(res, 'file response')
        fileDownload(res, `${'Reciept-' + receipt}.pdf`)
      }
    } catch (error) {
      console.log(error)
      //notification('error', 'Unable to download certificate, ICS FLAG- False !')
    }
  }




  const handleFileDownloadd = async () => {
    try {
      let AllData = {

        checkEightyG: paymentDetails.eightyGGenerated,
        receiptDate: paymentDetails.createdAt,
        rbno: paymentDetails.rbno,
        rno: paymentDetails.eightyGNo,
        amount: paymentDetails.totalAmount,
        mode: 'Online',
        bankName: paymentDetails.bankName,
        bankBranch: '',
        instrumentNo: paymentDetails.paymentId,
        instrumentDate: paymentDetails.createdAt,
        receiptURL: '',
        donorName: paymentDetails.name,
        donorAddress: paymentDetails.address,
        donorCity: paymentDetails.city,
        donorState: paymentDetails.state,
        donorCountry: 'India',
        donorPin: paymentDetails.pin,
        donorContact: paymentDetails.phone,
        donorEmail: paymentDetails.email,
        donorPAN: paymentDetails.pan,

      }



      const receipt = paymentDetails.eightyGNo;

     // const res = await ApiService.download80g(paymentDetails._id)
      // console.log(receipt);
      // console.log(res.message);
      const ress = await ApiService.download80g(`${paymentDetails._id}.pdf`)
          fileDownload(ress, `${'Reciept-Non 80G'}.pdf`)

    //  if (paymentDetails.isGotoIcs) {

        // if (res.message === 'Non 80G Generated successfully!') {

        //   const ress = await ApiService.download80g(`${receipt}.pdf`)
        //   fileDownload(ress, `${'Reciept-Non 80G'}.pdf`)
        // }

      // } else {
      //   notification('error', '80G Process can take between 12 to 24 hours to complete.You can also get in touch with us on the following email: support@iskconbhiwandi.org!')
      // }

      // console.log(res, 'file response')


    } catch (error) {
      console.log(error)
      //notification('error','Unable to download certificate, try again after some time!')
    }
  }

  const handleImgDownload = async () => {
    try {

      const res = await ApiService.downloadImg(imgname)
      // console.log(res)
      fileDownload(res, 'Annadaan-certificate.png')

    } catch (err) {
      console.log(err)
      // notification('error', "Unable to download certificate, try again after some time!")
    }
  }

  const check80g = async () => {
    try {
      const res = await ApiService.check80g(id, donationType)
      //  console.log(res)
      setShowDownload(res.hasEightyG)
      setShowCertificate(res.certificate)
      setEightyGGenerated(res.generated)
      if (res.generated) {
        setTimer(false)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const fetchCampaigns = async () => {
    try {
      let res = await ApiService.fetchCampaigns()
      setCampaigns(res)
    } catch (error) {
      console.log(error)
    }
  }

  const fetchFromIcs = async () => {

    const response = await ApiService.getIcsDonation_b(localStorage.getItem('ics_number'))

    setICSdata(response.data);
  }

  useEffect(() => {
    check80g()
    fetchPaymentDetails()
    fetchCampaigns()

    if (user) {
      fetchUser()
      fetchFromIcs()
    }

    setTimeout(() => {
      setTimer(false)
    }, 5000)

  }, [user])

  const handelIcs = (data) => {
    notification('error', data);
  }

  const handleDownload = (url, fileName) => {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      })
      .catch((error) => {
        console.error('Error downloading file:', error);
      });
  };

  return (
    <>

      <MetaData title="Thank You - ISKCON of Attapur" />
      <PageHeader title="Thank You" imgSrc="/images/page-header/25.jpg" />
      <section className="thank-you-container" ref={sectionRef}>
        {
          paymentDetails
            ?
            <div className="container">
              {
                paymentDetails?.paymentStatus === "success" &&
                <div id="scrollToThankYouPage" className="row align-items-center justify-content-between">
                  <div className={`${showDownload && showCertificate ? 'col-lg-5' : (!showCertificate ? 'col-lg-10 mx-auto' : 'col-lg-5')}`}>
                    <div className={`thank-you-text ${!showCertificate ? 'text-center' : ''}`}>
                      <div className={`heading ${!showCertificate ? 'text-center' : 'text-start'}`}>
                        <h2 className={`head-1 ${!showCertificate ? 'text-center' : 'text-start'}`}>Thank You!</h2>
                        <p className="mt-4" style={{ textAlign: 'center' }}>Dear {paymentDetails?.name} Ji,</p>

                        {
                          donationType !== 'subscription' ?
                            <>

                              <p className='mt-0' style={{ textAlign: 'justify' }}>
                                Thank You for your generous donation. As you have so lovingly given, so may the grace of Krishna flow into your life and may you, for all times, be in His refuge.
                              </p>
                              <p className='mt-0' style={{ textAlign: 'justify' }}>
                                As a token of our appreciation we will be happy to offer collective prayers in your name on your special occasions like your Birthday, Marriage Anniversary, Parent’s and Children’s birthdays or any other. Kindly share details in the form here.
                              </p>
                            </>


                            :

                            <>
                              <p className='mt-0' style={{ textAlign: 'justify' }}>Thank you for your generous donation. As you have so lovingly decided to participate in Nitya Seva by contributing to the services of the temple monthly, we are deeply grateful for your dedication and commitment to serving the lotus feet of Sri Sri Radha Madhava. </p>
                              <p className='mt-0' style={{ textAlign: 'justify' }}>You will be able to see all your donations by logging in to our website www.iskconbhiwandi.org. Nitya Seva commitment will also be visible on the dashboard. In future, if you decide to cancel the commitment, the same can be done by logging to our website.  </p>
                              <p className='mt-0' style={{ textAlign: 'justify' }}>
                                As a token of our appreciation, we will be happy to offer collective prayers in your name on your special occasion like your Birthday, Marriage Anniversary, Parents and children’s birthdays or any other. Kindly share the details in the form here. </p>

                            </>
                        }
                        <p className='mt-0' style={{ textAlign: 'center' }}>
                          Hare Krishna.
                        </p>
                      </div>
                      {paymentDetails.type === "Sri Chaitanya Charitamrita" ? '' :
                        <div className={`btns-wrap  align-items-center mt-4 ${!showCertificate ? 'd-inline-block justify-content-center' : ''}`}>
                          <Link to="/join-family" className={`d-inline-block text-center box-hover custom-btn mx-auto`}>JOIN THE FAMILY OF LORD KRISHNA</Link>
                          {paymentDetails.eightyGGenerated ?

                            <button className='mb-0 custom-btn-cls box-hover ms-0 ms-sm-2' onClick={() => paymentDetails?.isGotoIcs === false ? handelIcs('Your request is being processed. Details will be sent on email.') : handleDownload(paymentDetails?.receiptURL, `${'Reciept-' + paymentDetails?.eightyGNo}.pdf`)} >
                              DOWNLOAD 80G CERTIFICATE
                            </button> :
                            paymentDetails?.isGotoIcs === false ? '' :
                            <button className='mb-0 custom-btn-cls box-hover ms-0 ms-sm-2' onClick={() => { user ? setShow(true) : setLoginPopup(true); handelForm(); }} >
                              GENERATE 80G CERTIFICATE
                            </button>
                          }

                          {/* {
                          donationType === 'life-membership' &&
                          <button
                            className='mb-0 custom-btn-cls box-hover ms-0 ms-sm-2'
                            onClick={handleFileDownloadd}
                          // onClick={() => navigate(`/update-details?update-pan=${id}&&donation-type=lifeMembership`)}
                          >
                            DOWNLOAD 80G CERTIFICATE
                          </button>
                        } */}


                        </div>
                      }
                    </div>

                  </div>
                  {/* <div className={`${showDownload && showCertificate ? 'col-lg-7' : (!showDownload && !showCertificate ? 'col-10 d-none' : 'col-lg-3')}`}>
                    <div className="download-container">
                      <div className="row">
                        {
                          showDownload &&
                          <div className={`${showDownload && showCertificate ? 'col-sm-6' : 'col-12'}`}>
                            <div className="certificate-card">
                              <div>
                                <figure>
                                  <img src="/images/common/80G.png" alt="" />
                                </figure>
                                <h4 className='mt-3'>80G Certificate</h4>
                                <p className='mx-auto'>
                                  Download and save your 80G tax exemption certificate.
                                </p>
                              </div>
                              <div>
                                <button onClick={handleFileDownload} className='box-hover custom-btn mt-2'>Download</button>
                              </div>
                            </div>
                          </div>
                        }
                        {
                          showCertificate && 
                          <div className={`${showDownload ? 'col-sm-6' : 'col-12'}`}>
                            <div className="certificate-card">
                              <div>
                                  <a href={`${process.env.REACT_APP_CERTIFICATE_URI}/certificate-view/${paymentDetails?._id}`} target="_blank" className='d-block'>
                                    <figure className='mb-0'>
                                      <img src={`${process.env.REACT_APP_S3_URL}/uploads/certificate/${paymentDetails?._id}.jpg`} alt="" />
                                    </figure>
                                  </a>
                                  <h4 className='mt-3'>Annadaan Certificate</h4>
                                  <p className='mx-auto'>
                                  Share your certificate to boost social awareness!
                                  </p>
                              </div>
                              <div className="certificate-share-wrap">
                                <a target="_blank" href={`https://www.facebook.com/sharer.php?u=${process.env.REACT_APP_CERTIFICATE_URI}/certificate-view/${paymentDetails?._id}`}>
                                  <FaFacebookF />
                                </a>
                                <a target="_blank" href={`https://www.linkedin.com/sharing/share-offsite/?url=${process.env.REACT_APP_CERTIFICATE_URI}/certificate-view/${paymentDetails?._id}`}>
                                  <FaLinkedinIn />
                                </a>
                                <a target="_blank" href={`https://twitter.com/intent/tweet?url=${process.env.REACT_APP_CERTIFICATE_URI}/certificate-view/${paymentDetails?._id}&text=Hare%20Krishna&hashtags=#ISKCON`}>
                                  <FaTwitter />
                                </a>
                                <button onClick={handleImgDownload}>
                                  <FaDownload />
                                </button>
                              </div>
                            </div>
                          </div>
                        }
                      </div>
                    </div>
                  </div> */}
                  {
                    showCertificate &&
                    <div className="col-lg-7 mt-5 mt-lg-0">
                      <div className="thank-you-card-wrap">
                        <h2>
                          Share Your Support
                        </h2>
                        <p>
                          Continue to show support by helping create awareness & sharing on social media.
                        </p>
                        <div className="thankyou-card">
                          <div className="row align-items-sm-center">
                            <div className="col-sm-7">
                              <a href={`${process.env.REACT_APP_CERTIFICATE_URI}/certificate-view/${paymentDetails?._id}?type=${type}`} target="_blank" className='d-block'>
                                <figure className='mb-0'>
                                  <img src={`${process.env.REACT_APP_BACKEND_URL}/uploads/certificate/${paymentDetails?._id}.jpg`} alt="" />
                                </figure>
                              </a>
                            </div>
                            <div className="col-sm-5 ps-sm-0">
                              <div className="p-3 p-sm-0">
                                <div className="card-content d-sm-none d-block">
                                  <h4 className='mt-2'>Annadaan Certificate</h4>
                                  <p>Share your certificate to boost social awareness!</p>
                                </div>
                                <div className="social-contacts-wrap">
                                  <a target="_blank" href={`https://www.facebook.com/sharer.php?u=${process.env.REACT_APP_CERTIFICATE_URI}/certificate-view/${paymentDetails?._id}`}>
                                    <div className="share-div">
                                      <FaFacebookF />
                                      <h5>SHARE ON FACEBOOK</h5>
                                    </div>
                                  </a>
                                  <a target="_blank" href={`https://twitter.com/intent/tweet?url=${process.env.REACT_APP_CERTIFICATE_URI}/certificate-view/${paymentDetails?._id}&text=Hare%20Krishna&hashtags=#ISKCON`}>
                                    <div className="share-div">
                                      <FaTwitter />
                                      <h5>SHARE ON TWITTER</h5>
                                    </div>
                                  </a>
                                  <a target="_blank" href={`https://www.linkedin.com/sharing/share-offsite/?url=${process.env.REACT_APP_CERTIFICATE_URI}/certificate-view/${paymentDetails?._id}`}>
                                    <div className="share-div">
                                      <FaLinkedinIn />
                                      <h5>SHARE ON LINKEDIN</h5>
                                    </div>
                                  </a>
                                  <button onClick={handleImgDownload}>
                                    <div className="share-div mb-sm-0">
                                      <FaDownload />
                                      <h5>DOWNLOAD</h5>
                                    </div>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                </div>
              }
              {
                paymentDetails?.paymentStatus === "pending" &&
                <>
                  {
                    donationType !== 'subscription' ?
                      <div className="row align-items-center">
                        <div className="col-8 mx-auto">
                          <div className="thank-you-text text-center">
                            <div className="heading text-center">
                              <h2 className="text-center head-1">Payment Pending <img src="/images/common/pending.gif" alt="" /> </h2>
                              <p className="mt-4">Dear {paymentDetails?.name} Ji,</p>
                              <p className='mt-0'>
                                Your payment is being processed.
                              </p>
                              <p className='mt-0'>
                                Hare Krishna.
                              </p>
                            </div>
                            <Link to="/join-family" className='box-hover custom-btn mt-4 d-inline-block text-uppercase'>Join the family of Krishna</Link>
                          </div>
                        </div>
                      </div>
                      :
                      <div className="row align-items-center">
                        <div className="col-8 mx-auto">
                          <div className="thank-you-text text-center">
                            <div className="heading text-center">
                              <h2 className="text-center head-1">Your subscription will be active soon. <img src="/images/common/subscribe-loader.gif" alt="" /> </h2>
                              <p className="mt-4" style={{ textAlign: 'center' }}>Dear {paymentDetails?.name} Ji,</p>
                              <p className='mt-0' style={{ textAlign: 'justify' }}>
                                Thank you for your donation and monthly commitment. Your donation is very valuable to us.

                                Your monthly subscription is getting processed. It will take couple of minutes to record the recurring payments in your chosen mode of payment. Once the subscription ID is created by the payment gateway, we will be able to provide you the receipt of your donation made.

                              </p>
                              <p className='mt-0' style={{ textAlign: 'justify' }}>  Thank you for your patience, the payment is getting processed.
                                Please refresh the page after a couple of minutes.


                              </p>
                              <p>Note:- E-mandate Confirmation from bank will take 24 to 48 hours.</p>
                              <p className='mt-0' style={{ textAlign: 'center' }}>
                                While you wait, we highly recommend you chant -</p>
                              <p className='mt-0' style={{ textAlign: 'center' }}>
                                HARE KRISHNA HARE KRISHNA KRISHNA KRISHNA HARE HARE</p>
                              <p className='mt-0' style={{ textAlign: 'center' }}>
                                HARE RAM HARE RAM RAM RAM HARE HARE
                              </p>
                            </div>
                            <Link to="/join-family" className='box-hover custom-btn mt-4 d-inline-block text-uppercase'>Join the family of Krishna</Link>
                          </div>
                        </div>
                      </div>
                  }
                </>
              }
            </div>
            :
            <div className="container">
              <div className="row">
                <div className="col-lg-5">
                  <Skeleton height={400} />
                </div>
                <div className="col-lg-7">
                  <Skeleton height={400} />
                </div>
              </div>
            </div>
        }
        <div className="container">
          <div className="row mt-4">
            <div className="col-lg-6 mx-auto text-center">
              {
                user &&

                <Link to="/dashboard" className='custom-btn-cls box-hover'>
                  GO TO DASHBOARD
                </Link>
              }
              {paymentDetails?.paymentStatus === "pending" ? '' : user && paymentDetails?.eightyGGenerated ? '' :

                paymentDetails?.isGotoIcs === false ?
                  <button className='mb-0 custom-btn-cls box-hover ms-0 ms-sm-2' onClick={handleFileDownloadd}>
                    DOWNLOAD RECIEPT
                  </button> :
                  <button className='mb-0 custom-btn-cls box-hover ms-0 ms-sm-2' onClick={() => paymentDetails?.isGotoIcs === false ? handelIcs('Your request is being processed. Details will be sent on email.') : handleDownload(paymentDetails?.receiptURL, `${'Reciept-' + paymentDetails?.eightyGNo}.pdf`)}>
                    DOWNLOAD RECIEPT
                  </button>
              }


              {
                !user &&
                <>
                  <div className="thank-you-login-wrap mt-4">
                    <p>
                      To view any of your previous donation receipts, please login using your mobile number which was given while making the donation.
                    </p>
                    <button className='custom-btn-cls box-hover' onClick={() => setLoginPopup(true)}>
                      LOGIN
                    </button>
                  </div>
                </>
              }
            </div>
          </div>
        </div>
      </section>
      {/* <CampaignSlider campaigns={campaigns} /> */}

      {/* Donation Modal */}
      <Modal centered show={show} onHide={() => setShow(false)} className="eightyG-modal">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <p>As per Income Tax Department guidelines, for getting 80G certificate it is mandatory to provide complete Address and Name as on PAN card</p>

          {ics_id > 0 ?

            <button onClick={() => navigate(`/update-details?update-pan=${id}&uid=${ics_id}&donation-type=${donationType !== 'donation' ? (donationType !== 'subscription' ? 'kartik-deepdaan' : 'subscription') : 'campaign'}`, { replace: true })} className='mb-0 custom-btn-cls box-hover px-4' >
              OK
            </button>
            :
            <div
              className="spinner-border text-dark"
              role="status"
            ></div>

          }


        </Modal.Body>
      </Modal>

      {/* Life Membership Modal */}
      <Modal centered show={showModal} onHide={() => setShowModal(false)} className="eightyG-modal">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <p>As per Income Tax Department guidelines, for getting 80G certificate it is mandatory to provide complete Address and Name as on PAN card</p>

          {ics_id > 0 ?

            <button onClick={() => navigate(`/update-details?update-pan=${id}&uid=${ics_id}&donation-type=lifeMembership`, { replace: true })} className='mb-0 custom-btn-cls box-hover px-4' >
              OK
            </button>

            :

            <div
              className="spinner-border text-light"
              role="status"
            ></div>

          }



        </Modal.Body>
      </Modal>
      <GoToTop top={top} />
    </>
  )
}

export default ThankYou